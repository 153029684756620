@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/common-style';
@use 'src/theme/font-family';
@use 'src/theme/colour';

.currentInvoiceWrapper {
    padding: unset;

    .sectionHeader {
        @include common-style.flexbox-row;

        justify-content: space-between;
        padding: 1rem 1rem 5px;

        .linkText {
            font: 16px font-family.$raleway-regular;
            line-height: 34px;
            color: colour.$blue-2;
            cursor: pointer;
        }
    }
}

.currentInvoiceContent {
    .invoiceDetails {
        display: flex;
        width: 100%;
        flex-direction: column;

        @include mixins.screen('md') {
            flex-direction: row;
            /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
            flex-wrap: wrap;
        }

        .invoiceDetails__detail {
            padding: 1rem;
            white-space: nowrap;
            flex-basis: 100%;
            flex-shrink: 0;
            /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
            flex-grow: 1;

            @include mixins.screen('md') {
                flex-basis: 50%;
            }

            @include mixins.screen('xl') {
                flex-basis: auto;
                justify-content: space-between;
            }
        }
    }

    .totalPaymentWrapper {
        padding: 1.5rem;
        text-align: center;
        background-image: url('../../../../assets/icons/KeyLogoTealBackground.svg');
        align-items: center;
        justify-content: center;
        @include common-style.flexbox-column;

        gap: 0.5rem;
    }

    .label {
        font: 12px font-family.$raleway-regular;
        line-height: 16px;
        color: colour.$gray-4;
    }

    .monthlyBreakdownWrapper {
        .sectionHeading {
            display: flex;
            height: 27px;
            padding: 0 20px;
            font: 10px font-family.$raleway-bold;
            line-height: 11px;
            color: colour.$white-0;
            letter-spacing: 0.94px;
            background: colour.$black-0;
            align-items: center;
        }

        .breakdownHeader {
            @include common-style.flexbox-row;

            justify-content: space-between;
            width: 100%;
            padding-right: 10px;

            span {
                font: 16px font-family.$rawline-semibold;
                line-height: 22px;
                color: colour.$gray-6;
            }
        }

        .bodyWrapper {
            padding-top: 10px;
            padding-left: 17px;

            .breakdownBody {
                @include common-style.flexbox-row;

                justify-content: space-between;
                padding: 0 10px;

                span {
                    font: 14px font-family.$raleway-medium;
                    line-height: 34px;
                    color: colour.$gray-6;
                    letter-spacing: 0.26px;
                }
            }
        }

        .ownershipSavings {
            background: colour.$blue-0;
        }
    }
}
