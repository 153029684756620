@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/common-style';
@use 'src/theme/font-family';
@use 'src/theme/colour';

.paymentHistoryWrapper {
    @include common-style.flexbox-column;

    padding: 1rem;
    background-color: #fff;

    .sectionHeader {
        padding: 1rem;
        font: 12px font-family.$raleway-bold;
        line-height: 14px;
        color: colour.$gray-5;
        letter-spacing: 1.13px;
    }
}

.button {
    width: 100%;
    margin: 0 auto;

    @include mixins.screen('md') {
        width: 250px;
    }
}

.paymentHistoryContent {
    font-size: 1rem;
    line-height: 1.5;

    @include mixins.screen('md') {
        display: table;
    }

    .row {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 0.25rem;
        justify-content: space-between;
        padding: 1rem 0;

        @include mixins.screen('md') {
            display: table-row;
        }
    }

    .cell {
        font: 1rem font-family.$raleway-bold;

        @include mixins.screen('md') {
            display: table-cell;
            padding: 0.25rem;
        }
    }

    .cell:not(:first-child) {
        text-align: center;
    }

    .cell--view {
        width: 100%;
        padding-top: 1rem;
        text-align: right;

        @include mixins.screen('md') {
            width: auto;
        }
    }

    .row + .row {
        border-top: 1px solid colour.$gray-3;

        .cell {
            @include mixins.screen('md') {
                border-top: 1px solid colour.$gray-3;
            }
        }
    }

    .view {
        width: 100%;
        background-color: colour.$gray-3;
        border-color: transparent;

        @include mixins.screen('md') {
            width: auto;
            margin-left: auto;
        }
    }
}

.loadMoreButtonWrapper {
    width: 100%;
    padding: 2rem 1rem;
}
